export default function () {

    return (<div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
    }}>
        Loading...
    </div>)

}